<template>
  <div class="execution">
        <fromSearch :object_='object_' @searchFun='getList'/>
      <avue-crud ref="crud" :page="page" :data="page.list"
        :table-loading="listLoading"
        :option="tableOption"
        @size-change="sizeChange"
        @current-change="currentChange">
        <template #menu-left>
            <el-button v-if="admin_syspublicparam_del" type="primary"
                  @click="handleRefreshCache"  >{{ $t("button.refresh") }}  </el-button>
        </template>
        <template #caozuo="scope" >
            <el-button v-if="admin_syspublicparam_del" text
                type="primary" size="small"   @click="openView(1,scope.row)">{{$t("button.update")}}
            </el-button>
        </template>
      </avue-crud>
        <el-dialog v-model="dialogFormVisible" :title='isStyleText[isStyle]' width="600">
            <bindView ref='bindView_' @successFun='successFun' @closeFun='dialogFormVisible = false'/>
            <template #footer>
                <el-button  @click="dialogFormVisible = false">{{$t('button.closeQ')}} </el-button>
                <el-button type="primary" @click="completeFun_">{{$t('button.tijiao')}}</el-button>
            </template>
        </el-dialog>
  </div>
</template>

<script setup>
import { ref,getCurrentInstance,nextTick } from 'vue'
import Api_ from '@/api/index'
import { tableOption1 } from "@/const/crud/platform/parms";
import { ElMessage } from 'element-plus'
import bindView from './bind.vue'
import { object } from '@/const/from/platform/parms'
import fromSearch from '@/components/fromSearch.vue'

import mixins from '@/views/mixins/page'
const { $t } = getCurrentInstance().proxy;
let pageObj = mixins(['parmsList',['delParmsObj','publicId']],{systemFlag: 0})
const {page,dialogFormVisible,listLoading,sizeChange,currentChange,getList,successFun} = pageObj
const isStyle = ref(0)
const isStyleText = [$t("button.add"),$t("button.update")]
const object_ = ref(object);

const tableOption = ref(null);tableOption.value = tableOption1
 //用户权限
const admin_syspublicparam_del = ref(true)
const bindView_ = ref(null)


const handleRefreshCache = ()=>{
    Api_.refreshParmsObj().then(() => {
        ElMessage.success($t("alert.succSync"))
    })
}
const openView = (num,parm)=>{
    isStyle.value = num
    dialogFormVisible.value = true
    nextTick(()=>{
        bindView_.value.init_(num,parm)
    })
}
const completeFun_ = ()=>{
    bindView_.value.completeFun()
}
getList(1)
</script>
